import React from "react"
import PropTypes from "prop-types"

const Images = ({ data }) => {
  if (!data || !data.length > 0) return null

  return (
    <div className="grid gap-4 sm:grid-cols-autofit-normal sm:grid-rows-normal">
      {data.map(img => (
        <div
          key={img.originalName}
          className="relative flex flex-col items-center justify-center p-4 bg-gray-100 shadow"
        >
          <img
            className="max-w-full max-h-full"
            src={img.src}
            alt={img.originalName}
          />
          <span className="absolute bottom-0 right-0 px-6 py-1 text-xs text-white bg-gray-600">
            {img.originalName}
          </span>
        </div>
      ))}
    </div>
  )
}

Images.propTypes = {
  size: PropTypes.string,
}

Images.defaultProps = {
  size: "normal",
}

export default Images
