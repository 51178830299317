import React from "react"
import { graphql } from "gatsby"
import "@browniebroke/gatsby-image-gallery/dist/style.css"

import Message from "../../../components/message"
import Images from "../../../components/images"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Section from "../../../components/section"

const CouperoseRostraInfo = ({ data }) => {
  const beforeAfterImages = data?.beforeAfterImages?.nodes?.map(
    ({ childImageSharp }) => childImageSharp?.fluid
  )

  return (
    <Layout>
      <SEO title="Couperose - rostra" />

      <Section>
        <article className="prose lg:prose-xl">
          <h1>Couperose - rostra</h1>

          <Message>
            Zoals u ziet is er meer dat we kunnen bereiken met het licht van de
            VPL. Door een oplossing te zoeken naar ongewenst haar ontdekten
            professoren dat er meer gebeurde, niet alleen op de huid maar ook in
            en onder de huid was er actie ontdekt! Door de <u>lichtfilters</u>{" "}
            aan te passen konden ze het activiteitencentrum beïnvloeden en de
            volgende behandelingen werden mogelijk.
          </Message>

          <hr />

          <p>
            Hier gaan we te werk met een gele filter en een lagere energie. Door
            te flitsen op de huid gaat de onder de huid liggende
            haarvat-adertjes reageren door dat ze donkerder zijn dan de huid een
            nemen ze veel meer warmte op waarbij het bloed stolt waardoor het
            afgebroken wordt door je lichaam. Ook hierbij kan jij helpen de
            resultaten te verbeteren door afblijven en een afspraak boeken. Een
            goede voorbereidingsinfo is dus essentieel dit wordt uitvoerig
            uitgelegd bij een <b>inleidend gesprek of intakegesprek</b>.
          </p>

          <h2>Foto's</h2>

          <h3>Voor/na foto's</h3>
          <p>
            Krijg een indruk van wat de resultaten zijn van deze behandeling
          </p>
          <Images size="medium" data={beforeAfterImages} />
        </article>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query CouperoseRostraInfoPageQuery {
    generalImages: allFile(
      filter: { relativeDirectory: { eq: "info/couperose-rostra/general" } }
      sort: { fields: name, order: ASC }
    ) {
      ...GalleryImages
    }
    beforeAfterImages: allFile(
      filter: {
        relativeDirectory: { eq: "info/couperose-rostra/before-after" }
      }
      sort: { fields: name, order: ASC }
    ) {
      ...RegularImages
    }
  }
`

export default CouperoseRostraInfo
